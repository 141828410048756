@use "../../utils/variables" as v;
.about-page-container{
    margin: 0 20px;
}
.join-community-btn{
    text-decoration: none;
    color: v.$colorC;
}
.credential-images{
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    & > img{
        width: 100%;
    }
}
@media screen and (min-width: 641px){
    .credential-images{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;
    }
}