.soundbite{
    position: relative;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    & > .soundbite-container{
        display: inline-block;
        & > div{
            position: relative;
            &:last-child{
                & span{
                    position: absolute;
                    right: 0;
                }
            }
        }
    }
    & svg{
        height: 16px;
    }
}
@media screen and (min-width: 641px){
    .soundbite{
        font-size: 22px;
        & svg{
            height: 22px;
        }
    }
}