$backgroundA: #ffffff;
$backgroundB: #ccccff;
$backgroundC: #000044;
$backgroundD: #000000; /*#1A4C39;*/
$backgroundATXL: #ffffffde;
$backgroundATL: #ffffffbd;
$backgroundBTL: #ccccffbd;
$backgroundCTL: #000044bd;
$backgroundDTL: #000000bd; /*#1A4C39;*/
$backgroundATM: #ffffff7f;
$backgroundBTM: #ccccff7f;
$backgroundCTM: #0000447f;
$backgroundDTM: #0000007f; /*#1A4C39;*/
$backgroundATS: #ffffff63;
$backgroundBTS: #ccccff63;
$backgroundCTS: #00004463;
$backgroundDTS: #00000063; /*#1A4C39;*/
$colorA: #ffffff;
$colorATen: #ffffffa0;
$colorAEighty: #ffffff80;
$colorATwenty: #ffffff20;
$colorAD: #eeeeee;
$colorATXL: #ffffffde;
$colorATL: #ffffffbd;
$colorATM: #ffffff7f;
$colorATS: #ffffff63;
$colorB: #00ffff;
$colorBTen: #00ffffa0;
$colorBEighty: #00ffff80;
$colorBTwenty: #00ffff20;
$colorBD: #00efef;
$colorBTL: #00ffffbd;
$colorBTM: #00ffff7f;
$colorBTS: #00ffff63;
$colorC: #009FFF;
$colorCTen: #009fffa0;
$colorCEighty: #009fff80;
$colorCTwenty: #009fff20;
$colorCD: #008fef;
$colorCTL: #009fffbd;
$colorCTM: #009fff7f;
$colorCTS: #009fff63;
$colorD: #000000;
$colorDTen: #000000a0;
$colorDEighty: #00000080;
$colorDTwenty: #00000020;
$colorDTL: #000000bd;
$colorDTM: #0000007f;
$colorDTS: #00000063;

$blendRxxs: #ffcccc10;
$blendRxs: #ffcccc30;
$blendRs: #ffcccc50;
$blendRm: #ffcccc70;
$blendRl: #ffcccc90;
$blendRxl: #ffccccB0;
$blendRxxl: #ffccccD0;
$blendGxxs: #ccffcc10;
$blendGxs: #ccffcc30;
$blendGs: #ccffcc50;
$blendGm: #ccffcc70;
$blendGl: #ccffcc90;
$blendGxl: #ccffccB0;
$blendGxxl: #ccffccD0;
$blendBxxs: #ccccff10;
$blendBxs: #ccccff30;
$blendBs: #ccccff50;
$blendBm: #ccccff70;
$blendBl: #ccccff90;
$blendBxl: #ccccffB0;
$blendBxxl: #ccccffD0;