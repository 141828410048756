@use "../../utils/variables" as v;
.verify-email-page-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(../../assets/bg2.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #00000030;
    min-height: calc(100vh - 180px);
    & *
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    & .verify-form{
        background: #00000080;
        padding: 30px 60px;
    }
    & button{
        width: 100%;
    }
    & .email-sent{
        color: v.$colorC;
    }
}