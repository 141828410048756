@use "../../utils/variables" as v;

.header{
    background-color: v.$backgroundA;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
	-webkit-border-radius: 0 0 8px 8px;
	-moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
    margin-bottom: 50px;
    position: relative;
    & > nav{
        display: flex;
        justify-content: space-between;
        padding: 0.3em 0.5em;
        & > a:first-child{
            & > svg{
                position: relative;
                top: 3px;
            }
        }
        & a{
            text-decoration: none;
            color: v.$colorC;
            font-size: 16px;
            cursor: pointer;
        }
        & ul {
            margin: 0;
            padding: 0;
            list-style: none;
            flex-direction: column;
            justify-content: space-around;
            align-content: center;
            & li{
                cursor: pointer;
                & > a{
                    display: block;
                    border-radius: 8px;
                    background-color: v.$blendBxxs;
                    transition: all 0.15s;
                    margin-bottom: 1px;
                    &:hover{
                        background-color:v.$blendBxs;
                        box-shadow: 0px 0px 8px 4px v.$blendBxl;
                    }
                    &.active{
                        background-color:v.$blendBxs;
                        box-shadow: 0px 0px 8px 4px v.$blendBxl;
                    }
                }
            }
        }
    }
}
.logo-link{
    display: flex;
    justify-content: center;
    z-index: 9999;
    height: 51px;
    cursor: pointer;
}
.hamburger-btn{
    display: none;
    border: none;
    border-radius: 8px;
    background-color: v.$blendBm;
    width: 100%;
    height: 30px;
    margin-top: 6.5px;
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
        background-color: v.$blendBl;
    }
}
.close-hamburger-btn{
    display: none;
    border: none;
    border-radius: 8px;
    background-color: v.$blendBm;
    width: 100%;
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
        background-color: v.$blendBl;
    }
}
.page-links{
    display: none;
}
.collapse-links{
    width: 100%;
    overflow: visible;
    position: relative;
    z-index: 9997;
    background-color: v.$backgroundA;
    -webkit-box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.5);
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
    border-radius: 8px;
    height: 43px;
    overflow: hidden;
    padding: 6px 10px 2px 10px;
    position: relative;
    left: -10px;
    top: -4px;
    transition: all 0.3s;
    &.expanded{
        height: 173px;
    }
}

.mobile-header-btn{
    position: sticky;
    right: 0;
    top: 0;
    z-index: 5;
    & > div{
        float: right;
        padding: 10px 15px 10px 30px;
        clip-path: polygon(0% 0,100% 0,100% 100%,40% 100%);
        background: linear-gradient(60deg,#000 0 31%,v.$colorB 31% 31%, v.$colorC);
        border: 4px solid black;
        cursor: pointer;
        position: absolute;
        right: 0;
    }
}
.mobile-links{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    position: fixed;
    background: #0004;
    display: none;
    transition: all 0.2s;
    & nav {
        background: #fffe;
        & a{
            background: #009fff20;
            width: 100%;
            display: block;
            margin: 3px 0px;
            font-weight: 550;
            &:hover{
                background: #009fff40;
            }
            &.active{
                background: #009fff40;
            }
        }
    }
    &.visible{
        display: unset;
        animation: slidein 0.2s ease-in-out;
    }
}

@media screen and (min-width: 320px) {
    .header{
        height: 54px;
        margin-bottom: 50px;
        & > nav{
            flex-direction: column;
        }
        z-index: 9997;
    }
    .hamburger-btn.visible{
        display: unset;
    }
    .close-hamburger-btn.visible{
        display: unset;
    }
    .page-links.visible{
        display: unset;
    }
}
@media screen and (min-width: 481px) {
    
}
@media screen and (max-width: 640px) {
    .collapse-links{
        display: none;
    }
    .header{
        margin-bottom: 0;
    }
}
@media screen and (min-width: 641px) {
    .header{
        margin-bottom: 0;
        & > nav{
            flex-direction: row;
            & a:first-child > svg{
                top: 0;
            }
        }
    }
    .mobile-header-btn{
        display: none;
        &.visible{
            display: unset;
            & div{
                padding: 4px 15px 4px 200px;
                background: linear-gradient(24deg,#000 0 30%,v.$colorB 30% 31%,v.$colorC)
            }
        }
    }
    .hamburger-btn{

    }
    .close-hamburger-btn{
        
    }
    .collapse-links{
        margin-left: 10%;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        & .mobile-header-btn{
            position: unset;
        }
    }
}
@media screen and (min-width: 961px) {
    .header{
        & > nav{
            flex-direction: row;
            & ul{
                flex-direction: row;
                position: relative;
                top: 4px;
                & > li {
                    & > a{
                        margin: 0;
                        & button{
                            padding: 3px 4px;
                        }
                    }
                }
            }
        }
    }
    .mobile-header-btn{
        display: none;
        &.visible{
            display: none;
        }
    } 
    .hamburger-btn.visible{
        display: none;
    }
    .close-hamburger-btn.visible{
        display: none;
    }
    .page-links{
        display: flex;
        justify-content: space-around;
        &.visible{
            display: flex;
        }
    }
}
@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 1281px) {

}
@media screen and (max-width: 500px) {
    /*.logo-main{
        width: 90%;
    }*/
}
@keyframes slidein{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }
}