.definitions-page-content{
    margin: 0 20px;
    z-index: 2;
    position: relative;
    min-height: calc(100vh - 87.2px);
    overflow: hidden;
}
.definition-instruction-header{
    display: grid;
    position: sticky;
    top: 0;
    z-index: 3;
    background: white;
}