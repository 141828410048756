@use "./utils/variables" as v;
@import "~react-image-gallery/styles/scss/image-gallery.scss";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.input-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 60px;
}
.input-column{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contain-center{
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.page-content{
  min-height: calc(100vh - 92.2px);
}
.number-with-no-arrows{
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.inputBox, .signin-page-container .inputBox{
  position: relative;
  width: 100%;
  margin-top: 10px;
  &.error{
    color: red;
    & input, & textarea{
      border-bottom: 2px solid red;
      color: red;
    }
  }
  & input, & textarea{
      width: 100%;
      padding: 5px 10px;
      font-size: 16px;
      margin: 10px 0;
      border: none;
      color: v.$colorD;
      border-bottom: 2px solid v.$colorC;
      outline: none;
      background-color: v.$backgroundATL;
      resize: none;
  }
  & .box-label{
      position: absolute;
      left: 0;
      top: 0;
      padding: 5px 10px;
      font-size: 16px;
      margin: 10px 0;
      pointer-event: none;
      transition: 0.5s;
      color: #666;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  & input:focus ~ span, & input:valid ~ span, & textarea:focus ~ span, & textarea:valid ~ span{
      color: v.$colorB;
      text-shadow: 0px 0px 1px v.$colorC;
      font-size: 12px;
      transform: translateY(-20px);
      &.error{
          color: red;
          text-shadow: 0px 0px 1px #ff3333;
      }
  }
  & input[type="submit"]{
      width: 100%;
      /*background: v.$colorC;*/
      background: v.$backgroundDTS;
      box-shadow: 0px 0px 2px v.$colorC;
      color: v.$colorA;
      border: none;
      cursor: pointer;
      padding: 10px;
      font-size: 18px;
      &:hover{
          background: v.$colorCTL;
          background: v.$backgroundDTL;
      }
  }
  & span:not(.textarea){
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 10px;
    font-size: 16px;
    margin: 10px 0;
    pointer-event: none;
    transition: 0.5s;
    color: #666;
    -webkit-user-select: none;
    user-select: none;
  }
}
.section{
  &.highlightleft{
    width: 100%;
    background: v.$colorC;
    color: v.$colorA;
    min-width: 320px;
    min-height: 275px;
    z-index: -1;
    clip-path: polygon(0px 0px, 60% 0px, 40% 100.00%, 0px 100%);
    & h2{
      font-size: 40px;
    }
    & .highlight-spacer{
      shape-outside: polygon(100% 0px, 100% 100%, 40% 100%, 60% 0%);
      shape-margin: 1em;
      float: right;
      min-height: 275px;
      min-width: 320px;
      width: 100%;
    }
  }
  &.highlightright{
    width: 100%;
    background: v.$colorC;
    color: v.$colorA;
    min-width: 320px;
    min-height: 275px;
    text-align: end;
    clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 60% 100%);
    & h2{
      font-size: 40px;
    }
    & .highlight-spacer{
      shape-outside: polygon(0px 0px, 40% 0px, 60.00% 100.00%, 0px 100%);
      shape-margin: 1em;
      float: left;
      min-height: 275px;
      min-width: 320px;
      width: 100%;
    }
  }
}
@media screen and (max-width: 641px){
  .input-row{
    flex-direction: column;
    gap: unset;
  }
  .section{
    &.highlightleft{
      width: 100%;
      clip-path: unset;
      & .highlight-spacer{
        width: 0;
        height: 0;
        min-width: 0;
        min-height: 0;
      }
    }
    &.highlightright{
      width: 100%;
      clip-path: unset;
      text-align: start;
      & .highlight-spacer{
        width: 0;
        height: 0;
        min-width: 0;
        min-height: 0;
      }
    }
  }
}
.service-bullets{
  list-style: none;
  padding: 0;
  & li:before {
    content: '✓';
    color: v.$colorC;
    margin-right: 2px;
  }
}
.primaryColor{
  color: v.$colorC;
}
.secondaryColor{
  color: v.$colorB;
}