@use "../../utils/variables" as v;
.signin-page-container{
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(../../assets/bg2.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #00000030;
    min-height: calc(100vh - 180px);
    & *
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
}
.signin-page-container .inputBox{
    position: relative;
    width: 100%;
    margin-top: 10px;
    &.error{
      color: red;
      & input, & textarea{
        border-bottom: 2px solid red;
        color: red;
      }
    }
    & input, & textarea{
        width: 100%;
        padding: 5px 10px;
        font-size: 16px;
        margin: 10px 0;
        border: none;
        color: v.$colorD;
        border-bottom: 2px solid v.$colorC;
        outline: none;
        background-color: v.$backgroundATL;
        resize: none;
    }
    & .box-label{
        position: absolute;
        left: 0;
        top: 0;
        padding: 5px 10px;
        font-size: 16px;
        margin: 10px 0;
        pointer-event: none;
        transition: 0.5s;
        color: #666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    & input:focus ~ span, & input:valid ~ span, & textarea:focus ~ span, & textarea:valid ~ span{
        color: v.$colorB;
        text-shadow: 0px 0px 1px v.$colorC;
        font-size: 12px;
        transform: translateY(-20px);
        &.error{
            color: red;
            text-shadow: 0px 0px 1px #ff3333;
        }
    }
    & input[type="submit"]{
        width: 100%;
        /*background: v.$colorC;*/
        background: v.$backgroundDTS;
        box-shadow: 0px 0px 2px v.$colorC;
        color: v.$colorA;
        border: none;
        cursor: pointer;
        padding: 10px;
        font-size: 18px;
        &:hover{
            background: v.$colorCTL;
            background: v.$backgroundDTL;
        }
    }
  }