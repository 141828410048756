@use "../../utils/variables" as v;

.definition{
    position: relative;
    z-index: 1;
    overflow: hidden;
    & p{
        margin: 0;
        padding: 16px 0px;
    }
    & > p{
        cursor: pointer;
        margin: 0;
        padding: 16px 4px;
        border-bottom: double 3px black;
        background-color: v.$colorC;
        color: v.$colorA;
        &:hover{
            background-color:v.$colorCD;
        }
    }
    &:hover{
        background-color:v.$colorAD;
    }
    &.hidden{
        position: absolute;
        top: 0;
        left: 0;
        z-index:-1;
        opacity: 0;
    }
    margin-bottom: 16px;
}
.definition-contents{
    max-height: 0;
}