.page-not-found-container{
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(../../assets/notfound.webp);
    background-repeat: repeat-y;
    background-size:cover;
    background-color: #00000030;
    min-height: calc(100vh - 180px);
    color: white;
    & *
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
}