.popup-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .popup-container {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    padding: 1.5rem;
    margin: 1rem;
    max-width: 24rem;
    width: 100%;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: white;
    transition: color 0.2s;
    background: #009fff;
    border: none;
    border-radius: 4px;
  
    &:hover {
      color: #ff009f;
    }
  }
  
  .popup-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #009fff;
    margin-bottom: 1rem;
  }
  
  .popup-text {
    color: black;
    margin-bottom: 1rem;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .get-started-button {
    background-color: #009fff;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 600;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #0080cc;
    }
  }