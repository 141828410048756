@use "../../utils/variables" as v;
.services > .category-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    background: #ddd;
    padding: 20px;
    max-width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth; 
}
.service-item{
    display: grid;
    grid-template-rows: auto auto 1fr;
    height: clamp(568px, 100%, 800px);
    min-height: 568px;
    overflow-y: hidden;
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    overflow-x: hidden;
    border-radius: 8px;
    box-shadow: 2px 2px 6px gray;
    & > div{
        &:first-child{
            background: v.$colorC;
            height: 70px;
            color: white;
            font-size:20px;
            &.monthly{
                background: url(../../assets/mo.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }
            &.annually{
                background: url(../../assets/an.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        &:last-child{
            background: v.$colorA;
        }
        padding: 10px 6px;
    }
    & .servie-item-content{
        background-color: v.$colorA;
    }
    & .service-item-price{
        background: #eee;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        & span{
            &:first-child{
                text-decoration: gray line-through;
                font-size: 14px;
                display: none;
                &.visible{
                    display: unset;
                }
            }
            &:last-child{
                margin-left: 4px;
            }
        }
    }
    & .register-btn{
        button{
            width: 100%;
        }
    }
}
.arrow-scroller{
    background-color: v.$colorC;
    display: none;
    position: relative;
    top: -320px;
    transition: all 0.3s;
    width: 40px;
    opacity: 0.9;
    & svg{
        color: white;
        width: 40px;
        height: auto;
        padding: 20px 0px;
    }
    &:hover{
        background-color: #0080ff;
    }
}
.left-arrow-scroller{
    left: -20px;
}
.right-arrow-scroller{
    float: right;
    right: -20px;
}
.centerit{
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 1100px){
    .arrow-scroller{
        display: inline-block;
    }
}
@media screen and (min-width: 320px){
    //N=1
    //320-240*1-40(margin)-gap*2=0
    //width-cardWidth-2Margin-2Gap=0
    //Gap=(width/2-cardWidth/2-Margin)
    .services > .category-container{
        gap: calc(50vw - 140px);
        padding: 20px calc(50vw - 140px);
    }
}
@media screen and (min-width: 580px){
    //N=2
    //width-cardWidth*2-2Margin-3Gap=0
    //scroll=cardWidth+Gap
    //580-480-40
    //Gap=(width/3-cardWidth/3-2/3Margin)
    //Gap=33.33vw - 180
    .services > .category-container{
        gap: calc(33.33vw - 173.33px);
        padding: 20px calc(33.33vw - 173.33px);
    }
}
@media screen and (min-width: 840px){
    //N=3
    //width-cardWidth*3-2Margin-4Gap=0
    //Gap=width/4-180-30
    .services > .category-container{
        gap: calc(25vw - 190px);
        padding: 20px calc(25vw - 190px);
    }
}
@media screen and (min-width: 1100px){
    //N=4
    .services > .category-container{
        gap: 20px;
        padding: 20px;
    }
}