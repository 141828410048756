@use "../../utils/variables" as v;
.logo-svg{
    width: 300px;
}
.logo-extra-letters{
    fill: v.$colorB;
    stroke: v.$colorC;
    stroke-width: 0.5;
    stroke-linecap: butt;
    stroke-linejoin: miter;
}
.logo-main-letters{
    fill: v.$colorC;
    stroke: v.$colorB;
    stroke-width: 0.5;
    stroke-linecap: butt;
    stroke-linejoin: miter;
}
.logo-icon-body{
    fill: v.$colorC;
}
.logo-icon-ring{
    fill: v.$colorB;
}




.add-metadata{
    display: flex;
    flex-direction: column;
    & > div{
        display: flex;
        flex-direction: row;
        & > input{

        }
        & > button{
            height: 37.5px;
            top: 0;
            position: relative;
            left: 0;
            margin-top: 0;
            color: white;
            background-color: #014e6d;
            border: 1px solid #7adbd4;
            border-radius: 4px;
        }
    }
}