@use "../../utils/variables" as v;

div.signin-area{
    border-radius: 15px;
    background: #000000aa;
    max-width: 800px;
    min-width: 280px;
    border-radius: 15px;
    padding: 40px 80px;
}
.signinForm{
    padding: 40px;
    width: 100%;
    /*background-color: v.$backgroundATL;*/
    & h2{
        font-size: 30px;
        color: v.$colorB;
        /*text shadow*/
        text-shadow: 0px 0px 2px v.$colorC,
        0px 0px 2px v.$colorC,
        0px 0px 1px v.$colorC;
        font-weight: 500;
    }
    & .inputBox{
        position: relative;
        width: 100%;
        margin-top: 10px;
        & input, & textarea{
            width: 100%;
            padding: 5px 10px;
            font-size: 16px;
            margin: 10px 0;
            border: none;
            color: v.$colorD;
            border-bottom: 2px solid v.$colorC;
            outline: none;
            background-color: v.$backgroundATL;
            resize: none;
        }
        & .box-label{
            position: absolute;
            left: 0;
            top: 0;
            padding: 5px 10px;
            font-size: 16px;
            margin: 10px 0;
            pointer-event: none;
            transition: 0.5s;
            color: #666;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        & input:focus ~ span, & input:valid ~ span, & textarea:focus ~ span, & textarea:valid ~ span{
            color: v.$colorB;
            text-shadow: 0px 0px 1px v.$colorC;
            font-size: 12px;
            transform: translateY(-20px);
            &.error{
                color: red;
                text-shadow: 0px 0px 1px #ff3333;
            }
        }
        & input[type="submit"]{
            width: 100%;
            /*background: v.$colorC;*/
            background: v.$backgroundDTS;
            box-shadow: 0px 0px 2px v.$colorC;
            color: v.$colorA;
            border: none;
            cursor: pointer;
            padding: 10px;
            font-size: 18px;
            &:hover{
                background: v.$colorCTL;
                background: v.$backgroundDTL;
            }
        }
    }
}
.other-msg{
    color: v.$colorA;
    display: block;
}
.link-text{
    color: v.$colorB;
    cursor: pointer;
    &:hover{
        color: v.$colorC;
    }
}
fieldset.gender-input{
    border: none;
    height: 30.8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    margin: 10px;
    width: 189px;
    & div{
        display: flex;
        align-items: center;
    }
}
.dob-area{
    font-size: 20px;
    display: flex;
    flex-direction: row;
    & input{
        font-size: 20px;
        background-color: v.$backgroundATL;
        margin-top: 10px;
        border: none;
        border-bottom: 2px solid v.$colorC;
        height: 30.8px;
    }
    & span{
        color: v.$colorA;
        padding-top: 10px;
    }
}
@media screen and (max-width: 981px){
    div.signin-area{
        min-width: 280px;
        padding: 40px 40px;
    }
    
}